<template>
  <div>
    <div class="codeImg">
      <div class="flex flex-center">
        <img
          class=" codeImg-header"
          :src="user.avatar || '../assets/imgs/icon.png'"
          alt=""
        />
        <div>
          <div class="code-title">{{ user.name }}</div>
          <div class="code-text">{{ user.position }}</div>
        </div>
      </div>
    </div>

    <div class="codeContent flex column flex-center">
      <div class="codeContent-view flex flex-center">
        <img
          class="codeContent-view_img"
          src="../assets/imgs/corporateName.png"
          alt=""
        />
        <div>公司名称</div>
      </div>
      <div class="codeContent-view flex flex-center">
        <img
          class="codeContent-view_img"
          src="../assets/imgs/phone.png"
          alt=""
        />
        <div>{{ user.mobile }}</div>
      </div>
      <div class="codeContent-view flex flex-center">
        <img
          class="codeContent-view_img"
          src="../assets/imgs/address.png"
          alt=""
        />
        <div>{{ user.address || "暂无" }}</div>
      </div>
      <div class="codeLine"></div>
      <img class="codeContent-img" :src="user.qr_code" alt="" />
      <div class="codeContent-btn flex ">
        <!-- <div class="codeContent-btn_save flex-item_center" @click="save()">
          保存企微名片
        </div> -->
        <div class="codeContent-btn_share flex-item_center" @click="share()">
          分享电子名片
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      user: {
        avatar: "",
      },
    };
  },
  async mounted() {
    let that = this;
    this.getList();
    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      console.log("进来额");
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid,
        timestamp: result.timestamp,
        nonceStr: result.nonceStr,
        signature: result.signature,
        jsApiList: ["shareToExternalContact", "uploadImage", "downloadImage"],
      });
      that.$wx.ready(function() {
        that.$wx.agentConfig({
          corpid: result.corpid,
          agentid: result.agentid,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature2,
          jsApiList: ["shareToExternalContact", "uploadImage", "downloadImage"],
          success: function(res) {
            console.log("res", res);
            // 回调
          },
          fail: function(res) {
            console.log("that.$wx.agentConfig err", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
        that.$wx.error(function(res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      });
    }
  },
  methods: {
    share() {
      let that = this;
      that.$wx.invoke(
        "shareToExternalContact",
        {
          text: {
            content: "我的二维码", // 文本内容
          },
          attachments: [
            {
              msgtype: "image", // 消息类型，必填
              image: {
                imgUrl: that.user.qr_code,
              },
            },
          ],
        },
        function(res) {
          if (res.err_msg == "shareToExternalContact:ok") {
          }
        }
      );
    },
    save() {
      // var alink = document.createElement("a");
      // alink.href = this.user.qr_code
      // alink.download = "pic"; //图片名
      // alink.click();
      // var a = document.createElement("a");
      // var event = new MouseEvent("click");
      // // 自定义下载后图片的名称
      // a.download = "二维码";
      // a.href = this.user.qr_code;
      // a.dispatchEvent(event);
      // let a = document.createElement("a");
      // a.setAttribute("download", "二维码");
      // a.href = this.user.qr_code;
      // a.click();
      let that = this;
      // window.location.href = that.user.qr_code
      // window.open(that.user.qr_code);

      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      // eslint-disable-next-line func-names
      image.onload = function() {
        image.crossOrigin = 'anonymous';
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        const url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
        const a = document.createElement("a"); // 生成一个a元素
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.click();
      };
      image.src = that.user.qr_code;

      // that.$wx.uploadImage({
      //   localId: that.user.qr_code, // 需要上传的图片的本地ID，由chooseImage接口获得
      //   isShowProgressTips: 1, // 默认为1，显示进度提示
      //   success: function(res) {
      //     console.log("that.$wx.uploadImage res", res);
      //     var serverId = res.serverId;
      //     wx.downloadImage({
      //       serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
      //       isShowProgressTips: 1, // 默认为1，显示进度提示
      //       success: function(res) {
      //         var localId = res.localId; // 返回图片下载后的本地ID
      //       },
      //     });
      //   },
      //   fail(err) {
      //     console.log("that.$wx.uploadImage err", err);
      //   },
      // });
    },
    async getList() {
      let res = await request._userInfo({
        userid: localStorage.getItem("userid"),
      });
      console.log("_userInfo res", res);
      if (_.toInteger(res.code) === 1) {
        this.user = res.data;
      }
    },
  },
};
</script>

<style scoped>
.codeImg {
  width: 100%;
  background: url("../assets/imgs/homeBg.png");
  background-size: 100% 100%;
  height: 529px;
  padding: 69px 0 0 40px;
}

.codeImg-header {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 25px 0 0;
}

.code-title {
  font-size: 40px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #101f48;
  margin: 0 0 13px 0;
}

.code-text {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.codeContent {
  width: 690px;
  position: relative;
  height: 920px;
  padding: 59px 0 64px 0;
  background: linear-gradient(180deg, #e8efff 0%, #ffffff 7%, #ffffff 100%);
  box-shadow: 0px 10px 50px 1px rgba(161, 182, 248, 0.27);
  border-radius: 24px 24px 24px 24px;
  margin: -240px auto 0;
}

.codeContent-view {
  width: 620px;
  margin-bottom: 24px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.codeContent-view_img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.codeLine {
  width: 100%;
  height: 1px;
  margin-top: 30px;
  background: #ededf7;
}

.codeContent-img {
  margin-top: 64px;
  width: 364px;
  height: 364px;
}

.codeContent-btn {
  padding: 65px 0 0 0;
  justify-content: center;
}

.codeContent-btn_save {
  width: 264px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
}

.codeContent-btn_share {
  width: 264px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  height: 80px;
  background: #0558f4;
  margin-left: 16px;
  border-radius: 16px 16px 16px 16px;
}
</style>
